* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px !important;
}

.MuiTypography-root {
  color: #020A1A;
}

.MuiTypography-body2, .MuiFormControlLabel-label {
  color: #848C9C;
}

.MuiGrid-item {
  padding-left: 0px !important;
  padding-top: 0px !important;
}